.dropdown{
    /* box-shadow: 0px 6px 20px #C1CFD933;
    border-radius: 10px; */
    background: #FFFFFF;
    width: 500px;
}

.control-class{
    /* width: 100%; */
    box-shadow: 0px 6px 20px #FFFFFF;
    background: #FFFFFF; 
    border-radius: 10px;
}

.place-holder{
    color: #479EFF;
    font-size: 15px;
    font-weight: bold;
    text-align: left;
}

.menu-items{
    color: #737783;
    font-size: 15px;
    text-align: left;
    white-space: nowrap;
}