
.button{
    border: 1px solid #5C9EF8;
    color: #5C9EF8;
    background: none;
    padding: 0.375rem ;
    margin: 1rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    border-radius: .25rem;
    width: 8rem;
}

.btn-success button{
    margin: 1rem;
    width: 8rem;
}
.img{
    float: right;
}

.radio{
    margin: 5px;
}

.top-pad {
    padding-top: 20px !important;
    display: flex;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }
}
.export-icon {
    width: 30px;
    height: 30px;
    padding-right: 5px;
}

.common-pass {
    width: 30px;
    height: 26px;
    padding-right: 5px;
}
.cert-top{
    padding-left: 15rem;
}

.center-align{
    display: flex !important; 
    align-items: center;
    flex-direction: column;
    justify-content: center !important;
}

@media only screen and (max-width: 500px) {
    .no-print{
        width: 70%;
    }
    .certificate{
        width: 65%;
    }
}

@media only screen and (max-width: 1024px) {
    .cert-top{
        width: 720px;
    }
}