
.dropdown {
    float:left; 
 }
 
 .state-text-field{
     text-align: left;
     padding-left: 5px;
     padding-bottom: 10px;
 }
 
 .state-dropdown {
     padding: 20px;
     padding-right: 400px;
     color: #479EFF;
     font-size: 12px;
     font-weight: bold;
     box-shadow: 0px 6px 20px #C1CFD933;
     border-radius: 10px;
 }
 
 .type-of-bubbles {
     padding-top: 1em;
     clear:both;
     display: flex;
 }
 
 .container {
     display: flex;
     justify-content: center;
     align-items: center;
     margin-top: 2rem;
 }
 
 .map-container{
     width: 100%;
     margin-left: 1em;
 }
 
 .select-state {
     padding: 10px;
     text-align: left;
     color: #1D1D1D;
     font-weight: bold;
 }
 
 .heading{
     font-weight: bold;
     margin-bottom: 10px;
 }
 
 .centres{
     display: flex;
 }
 
 .centre-box{
     border: 1px solid #91C5FF;
     border-radius: 10px 0px 0px 10px;
     padding: 20px;
     display: flex;
     text-align: left;
     margin-bottom: 10px;
     width: 130px;
 }
 
 .middle-shape {
     border: 1px solid #91C5FF;
     padding: 20px;
     display: flex;
     text-align: left;
     margin-bottom: 10px;
     width: 130px;
 }
 .details{
     padding-left: 10px;
 }
 
 .private-box {
     border: 1px solid #91C5FF;
     border-radius: 0px 10px 10px 0px;
     padding: 20px;
     display: flex;
     text-align: left;
     margin-bottom: 10px;
     width: 130px;
 }
 
 .numbers{
     color: #479EFF;
     font-weight: bold;
 }
 
 .texts {
     color: #1D1D1D;
     font-size: 10px;
 }
 
 .thead{
     background: #4E67D1;
     color: #FFFFFF;
 }
 
 