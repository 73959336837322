.select-program-container {
    padding: 3rem 5rem;
    height: 100%;
}

.form-container {
    padding: 3rem;
    height: 100%;
}

.select-program-container .container-fluid {
    height: 100%;
}

.select-program-container .back-btn{
    color: #5C9EF8;
    font-weight: bold;
    font-size: 18px;
}
@media (max-width: 961px) {
    .side-effect-container {
        padding: 1rem 0.5rem;
    }
    .form-container {
        padding: 1rem 0.5rem;
    }
    .select-program-container {
        padding: 1rem 0.5rem;
    }
}

.program-card {
    text-align: center;
}

.program-tick {
    z-index: 1;
    position: absolute;
    top: -9px;
    right: -9px;
}

.selected-program-img {
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    background: white;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../../../assets/img/icon-dropdown.svg) 96% / 04% no-repeat #EEE;
}
