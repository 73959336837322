.appointment-container {
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.appointment-container .card-container {
    width: 90%;
    height: 100%;
    padding: 2rem;
}

.appointment-container .header-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.appointment-container .header-group img {
    transform: scale(-1);
}

.header-group .appointment-back-btn {
    color: #5C9EF8;
    font-weight: bold;
    font-size: 18px;
}

.facility-list-wrapper {
}

.facility-list {
    max-height: 60vh;
    overflow: auto;
}

.facility-list .active {
    background-color: #F4F4F4;
}

.facility-list .facility-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #D8D8D8;
    padding: 1rem;
    cursor: pointer;
}

.facility-list .facility-card:hover {
    background-color: #F4F4F4;
}

.facility-list .facility-card .facility-list-detail {
    color: #707070;
}

.allotment-wrapper .days-list-wrapper {
    display: flex;
    justify-content: space-around;
    height: 53vh;
    overflow: auto;
}
.slot-booking-btn {
    color: #65E8AC !important;
    border-color: #65E8AC !important;
    width: 5rem;
    border-width: 2px !important;
    font-weight: bold !important;
}
.slot-booking-btn:hover {
    color: #fff !important;
    border-color: #65E8AC !important;
    background-color: #65E8AC !important;
    width: 5rem
}
.slot-booking-btn-disabled {
    opacity: 0.4;
    cursor: not-allowed !important;
}
.slot-booking-header {
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 0.5rem;
}

.facility-list .badge {
    color: #fff;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 25px;
    margin: 0 5px;
}

.facility-list .purple {
    background: #A7B8FF;
}


.facility-list .green {
    background: #65E8AC;
}

.cancel-btn-appnt {
    font-size: 18px;
    color: cornflowerblue;
    font-weight: bold;
    padding: 5px;
}

@media only screen and (max-width: 768px) {
    .appointment-container .card-container {
        padding: 1rem 0rem;
    }
}