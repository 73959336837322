.table > thead > tr:first-child > th:nth-child(2), th:nth-child(3) {
  background: #848484;
  box-shadow: 0px 0px 5px #848484;
}

.table > thead > tr:first-child > th:nth-child(4), th:nth-child(5) {
  background: #479EFF;
  box-shadow: 0px 0px 5px #479EFF;
}
.table > thead > tr:first-child > th:nth-child(6), th:nth-child(7), th:nth-child(8){
  background: #74C9A7;
  box-shadow: 0px 0px 5px #74C9A7;
}

.table-container {
  height:500px;
  overflow:auto;  
  margin: 0px 10px 0px 10px;
  width: 100%;
}

.table{
  width: 80%;  
}
.td{
  width: 25%;
}
.td-start{
  color: #4E4E4E;
  font-weight: bolder;
  background: #FFFFFF;
  font-size: 12px;
  text-align: left;
}
.td-header{
  color: #FFFFFF;
  font-weight: bolder;
  font-size: 12px;
  padding: 10px;
}

.td-body {
  box-shadow: 0px 6px 20px #C1CFD933;
  border-radius: 0px 0px 10px 10px;
  background: #F7F7F7;
  color: #4E4E4E;
  font-size: 12px;
}

.td-state {
  background: #FFFFFF;
  text-align: left;
  color: #737783;
  font-size: 12px;
}

.td-cell{
  box-shadow: 0px 6px 20px #C1CFD933;
  border-radius: 0px 0px 0px 0px;
  padding:10px;
  color: #4E4E4E;
  font-size: 10px;
}

.cell1{
  background: #F7F7F7;
}

.cell2 {
  background: #F7FBFF;
}

.cell3{
  background: #F2F7F5;
}