.certificate-container {
    box-shadow: 0 0 8px #00000029;
    background-color: white;
}

.certificate {
    margin: 1em;
    font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

}

.certificate td {
    padding: 0;
}

.logo {
    width: 30%;
    padding-bottom: 2em;
}

.b500 {
    font-weight: 500 !important;
}

.top-pad {
    padding-top: 20px !important;
}

.spacer-height {
    height: 2em;
}

.footer {
    width: 400px;
}
td {
    padding-top: 5px;
}
.program-logo{
    width: 32px;
}

@media only screen and (max-width: 600px) {
    .certificate{
        font-size: small;
    }
    .certificate>tbody>tr>:nth-child(1){
        white-space: nowrap;
    }

  }