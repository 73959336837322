.main-container .container-fluid {
    height: 100%;
}

.members-container {
    padding: 3rem 5rem;
    height: 100%;
}

.reciepient-card span {
    margin-right: 10px;
    font-size: smaller;
}

.reciepient-card > div {
    padding-top: 5px;
}

.members-container .card {
    border: 0;
}

@media (max-width: 1000px) {
    .main-container {
        padding: 1rem 0.5rem;
    }
    .members-container {
        padding: 1rem 0.5rem;
    }
}

.main-container {
    background-color: #F9FAFA;
    height: calc(100vh - 6rem);
    overflow: auto;
}

.member-card {
    box-shadow: 0px 6px 20px #C1CFD933 !important;
    margin-bottom: 1rem;
    border-radius: 10px !important;
}

.member-card-header {
    background-color: white !important;

    color: #5C9EF8;
    font-weight: bolder;
    padding: 1rem 3rem!important;
    font-size: 18px;
}


.member-card-body {
    background-color: white !important;
    padding: 1rem 3rem!important;
}

.appointment-wrapper {
    border-bottom: 3px dashed gainsboro;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    display: flex;
}

.appointment-details {
    width: 15%;
    display: flex;
    flex-direction: column;
}

.appointment-title {
    color: #646D82;
    padding-bottom:  .5rem;
}

.appointment-schedule {
    width: 70%;
}

.appointment-active-circle {
    width: 2rem;
    height: 2rem;
    border-radius: 100px;
}

.appointment-inactive-circle {
    background-color: #F8F8F8;
    border: 1px solid #E6E6E6;
    width: 2rem;
    height: 2rem;
    border-radius: 100px;
}

.appointment-inactive-title {
    color: #B1B1B1;
    font-size: 14px;
}

.appointment-active-title {
    font-size: 14px;
}

.appointment-line {
    position: absolute;
    border: 2px solid #E6E6E6;
    width: 90%;
    /*height of circle*/
    top: calc(2rem / 2);
    left: 1rem;
}

.appointment-line-mini {
    width: 80%;
}


@media only screen and (max-width: 1060px) and (min-width: 1001px){
    .appointment-line {
        width: 85%;
    }

    .appointment-line-mini {
        width: 70%;
    }
}


.appointment-link-btn {
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    font-size: 14px !important;
    font-weight: normal !important;
}

.appointment-add-program {
    border: 2px solid #5C9EF8;
    border-radius: 261px;
    font-weight: bold;
    font-size: 2rem;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-program-modal .shadow-sm {
    box-shadow: none !important;
    padding: 0 !important;
}

.select-program-modal .shadow-sm .comorbidities-wrapper {
    padding-top: 0 !important;
}

.select-program-modal .shadow-sm .comorbidities-list-wrapper {
    min-height: 20vh;
    max-height: 60vh;
    max-width: 100%;
    overflow: auto;
}

.appointment-timeline {
    display: flex;
    justify-content: space-between;
    position: relative;
}

.timeline-node {
    display: flex;
    flex-direction: column;
}

.timeline-node-text {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 920px) {
    .member-card {
        font-size: 14px;
    }
    .member-card-header {
        font-size: 14px !important;
    }

    .appointment-inactive-title {
        font-size: 12px !important;
    }

    .appointment-active-title {
        font-size: 12px !important;
    }
    .appointment-link-btn {
        font-size: 12px !important;
    }

    .appointment-add-program {
        font-size: 1.5rem !important;
    }

}


@media only screen and (max-width: 768px) {
    .allotment-wrapper {
        font-size: 14px;
    }
    .allotment-wrapper h5{
        font-size: 14px;
    }
    .appointment-schedule {
        width: 100%;
    }
    .member-card-header {
        padding: 1rem 1rem!important;
    }
    .member-card-body {
        padding: 1rem 1rem!important;
        padding-top: 0 !important;
    }
    .appointment-wrapper {
        flex-direction: column;
    }
    .appointment-details {
        width: 100%;
    }
    .appointment-title {
        padding-bottom: 0;
        padding-top: 0.5rem;
    }
    .appointment-timeline {
        flex-direction: column;
    }
    .appointment-line {
        width: 0;
        height: 80%;
    }
    .timeline-node {
        flex-direction: row;
        padding-top: 1rem;
    }
    .appointment-inactive-circle {
        margin-right: 0.5rem;
    }
    .appointment-active-circle {
        margin-right: 0.5rem;
    }
    .timeline-node-text {
        justify-content: center;
    }
    .members-container .alert-warning {
        font-size: 14px;
    }
    .members-container .custom-button {
        font-size: 14px;
    }
    .appointment-schedule .w-50 {
        width: 100% !important;
    }
    .select-program-modal {
        font-size: 14px;
    }
    .select-program-modal h3{
        font-size: 14px;
    }
    .select-program-modal .position-absolute img{
        width: 80%;
    }
    .select-program-container {
         padding-top: 1.5rem !important;
         display: flex;
         flex-direction: column;
         align-items: center;
     }

    .select-program-container >:first-child {
        align-self: flex-start;
    }
    .modal {
        top: 5% !important;
        height: 90% !important;
    }
    .select-program-modal .shadow-sm .comorbidities-list-wrapper {
        max-height: 40vh !important;
    }
}


@media only screen and (max-width: 768px) and (min-width: 400px){
    .member-card-header {
        padding: 1rem 2rem!important;
    }
    .member-card-body {
        padding: 1rem 2rem!important;
        padding-top: 0 !important;
    }
}

@media only screen and (min-width: 770px){
    .appointment-line {
        width: 76%;
    }

    .appointment-line-mini {
        width: 71%;
    }
}

@media only screen and (min-width: 1010px){
    .appointment-line {
        width: 76%;
    }

    .appointment-line-mini {
        width: 71%;
    }
}

@media only screen and (min-width: 1170px){
    .appointment-line {
        width: 80%;
    }

    .appointment-line-mini {
        width: 72%;
    }
}


@media only screen and (min-width: 1390px){
    .appointment-line {
        width: 84%;
    }

    .appointment-line-mini {
        width: 75%;
    }
}

@media only screen and (min-width: 1660px){
    .appointment-line {
        width: 87%;
    }

    .appointment-line-mini {
        width: 75%;
    }
}

@media only screen and (min-width: 1970px){
    .appointment-line {
        width: 89%;
    }

    .appointment-line-mini {
        width: 78%;
    }
}
@media only screen and (min-width: 2250px){
    .appointment-line {
        width: 91%;
    }

    .appointment-line-mini {
        width: 82%;
    }
}