.citizen-login {
  padding: 10vh;
}


.citizen-login body {
  font-family: Avenir, serif;
}

.citizen-login input {
  height: 60px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #5C9EF8;
  border-radius: 4px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #4D4F5C;
  margin-right: 60px;
  width: 100%;
}

.citizen-login button {
  top: 554px;
  left: 177px;
  background: transparent linear-gradient(91deg, #59BCF9 0%, #4E67D1 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
}

.transparent-button {
  background: Transparent none no-repeat !important;
  cursor:pointer;
  overflow: hidden;
  outline:none;
  margin-right: 10vh;
}

.login-otp label {
  position: absolute;
  padding-top: 1rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  color: #4E67D1;
}

.login-otp input {
  width: 100%;
  padding-left: 4rem;
  letter-spacing: 0.75em !important;
}

.login-otp {
  box-sizing: border-box;
}

.login-otp input:disabled {
  border-color: #FFFFFF;
}

.citizen-login input:disabled {
  border-color: #FFFFFF;
}

@media only screen and (max-width: 500px) {
  .citizen-login {
    padding: 4vh;
  }
}