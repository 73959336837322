.confirm-container {
    padding: 5rem 5rem 5rem 10rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

@media (max-width: 961px) {
    .confirm-container {
        padding: 2rem;
    }
}
