

.table-container>table>tbody{
    height: 60vh;
    overflow-y: scroll;
    display:block;
}

.table-container>table>thead {
    display: block;
}
.table-container>table>thead>tr {
    display: flex;
    justify-content: space-between;
}
.table-container{
    padding: 5px;
}

.table-container>table>thead{
    background: #4E67D1;
    color: white;
}

